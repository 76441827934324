//MEDIA MIXINS
@mixin large-mobile {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin minWidth($val) {
  @media screen and (min-width: $val) { @content; }
}

@mixin maxWidth($val) {
  @media (max-width: $val) { @content; }
}

// BUTTON MIXINS
@mixin commonBtnStyles {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: inherit;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 28px;
  border-radius: 6px;
  color: $whiteMain;
  transition: all .3s ease;
  border: 1px solid transparent;
}
@mixin btn-primary {
  background: $primaryColor;
  &:hover,
  &:focus {
    color: $whiteMain;
    background-color: $primaryColor;
    box-shadow: 0 5px 20px -4px rgba(#29664D, .5);
  }
  &:active {
    background: $primaryColor;
  }
  &:disabled {
    opacity: 0.5;
  }
}

@mixin btn-secondary {
  background-color: $secondaryColor;
  color: $primaryColor;

  &:hover{
    background:  $primaryColor;
    color: $alwaysWhite;
  }
  &:active {
    background:  $secondaryColor;
    color: $primaryColor;
  }
  &:disabled {
    opacity: 0.5;
  }
}
@mixin btn-border {
  background-color: transparent;
  border-color: $primaryColor;
  color: $primaryColor;

  &:hover {
    border-color: transparent;
    background-color: $primaryColor;
    color: $alwaysWhite;
  }
  &:active {
    background-color: transparent;
    border-color: $primaryColor;
    color: $primaryColor;
  }
  &:disabled {
    opacity: 0.5;
  }
}

@mixin btn-hollow {
  background: transparent;
  color: $whiteMain;
  font-weight: bold;
  border: 1px solid $teal;

  &:hover,
  &:focus {
    background: $teal;
  }
  &:active {
    background: $gradientGreen;
  }
  &:disabled {
    color: $grayText;
    border-color: $grayText;
  }
}

@mixin btn-tertiary {
  background: $tertiaryBtnBg;
  box-shadow: 0 8px 20px $secondaryBtnShadow;
  border-color: $tertiaryBtnBg;
  color: $tertiaryBtnColor;
  &:hover,
  &:focus {
    color: $tertiaryBtnColor;
    background: $alwaysWhite;
    border-color: $tertiaryBtnColor;
  }
  &:active {
    color: $tertiaryBtnColor;
    background: $tertiaryBtnBg;
    border-color: $tertiaryBtnColor;
  }
  &:disabled {
    background: $tertiaryBtnDisabled;
    border-color: $tertiaryBtnDisabled;
    color: $lightsteelblue_bg;
    svg {
      path {
        fill: $lightsteelblue_bg;
      }
    }
  }
}

@mixin btn-default {
  background: $defaultBtnBg;
  box-shadow: 0 8px 20px $defaultBtnShadow;
  border-color: $defaultBtnBg;
  color: $defaultActiveMain;
  &:hover,
  &:active,
  &:focus {
    color: $defaultActiveMain;
    background: $defaultBtnBgHover;
    border-color: $defaultBtnBgHover;
  }
}

@mixin with-icon {
  padding-left: 24px !important;
  padding-right: 17px !important;
  flex-direction: row-reverse;

  @include btn-tertiary;

  svg {
    display: inline-block;
    margin-left: 10px;
  }
}

@mixin loading {
  &-icon {
    font-size: 20px;
  }
}