.nav-cards {
  margin-top: 50px;

  @include media("<568px") {
    margin-top: 30px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    width: 25%;
    padding: 0 6px;
    padding-bottom: 12px;

    @include media("<=860px") {
      width: 50%;
    }
    @include media("<568px") {
      width: 100%;
    }
  }
}

.nav-card {
  height: 125px;
  background: #fff;
  color: #000;
  display: flex;
  align-items: center;
  padding: 25px 30px;
  border-radius: 10px;

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    margin: 0;
    margin-bottom: 10px;
  }
  &__icon {
    margin-left: auto;
  }
}
