.main-banner {
  z-index: 1;

  .slick-slider {
    .slick-dots {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 45px;
      z-index: 1;
      width: auto;

      @include maxWidth($media_lg) {
        bottom: 20px;
      }
      li {
        width: fit-content;
        height: fit-content;
        margin: 0;

        &:not(:last-child) {
          margin: 0 5px 0 0;
        }

        &.slick-active {
          button {
            width: 68px;
            background: $alwaysWhite;
          }
        }

        button {
          width: 23px;
          height: 8px;
          border-radius: 21px;
          background: rgba(21, 189, 198, 0.5);
          padding: 4px;

          &:before {
            display: none;
          }
        }
      }
    }
  }

}

.banner-slide {
  height: 400px;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 90px;
  padding-left: 80px;
  padding-top: 100px;
  text-align: left;

  @include media("<568px") {
    height: 75vw;
    padding-bottom: 15vw;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, .25);
      z-index: 4;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 5;
  }
  &__title {
    font-weight: 900;
    font-size: 44px;
    line-height: 1.3;
    margin-bottom: 10px;

    @include media("<568px") {
      font-size: 26px;
    }
  }
  &__sub-title {
    font-size: 18px;
    line-height: 1.3;
    color: $alwaysWhite;

    @include media("<568px") {
      font-size: 14px;
    }
  }
  &__btn {
    margin-top: 15px;
  }
}