
.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding: 0;
  list-style: none;

  &__item {
    width: calc(100% / 3);
    padding: 0 12px;
    padding-bottom: 24px;

    @include media('<=860px') {
      width: 100%;
    }

  }
}

.benefit-card {
  background-color: $whiteBox;
  height: 100px;
  border-radius: 10px;
  padding-left: 175px;
  color: $alwaysBlack;
  position: relative;
  font-size: 16px;
  line-height: 1.3;
  font-weight: bold;
  display: flex;
  align-items: center;

  @include media("<568px") {
    padding-left: 125px;
    font-size: 14px;
  }
  &__title {
    margin: 0;
    max-width: 175px;
  }
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 40px;

    @include media("<568px") {
      left: 20px;
      width: 75px;

      img {
        max-width: 100%;
      }
    }
  }
}