@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";
@import "../assets/styles/main";

body {
  background: $main_bg;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  //height: 100%;
  width: 100%;
  //overflow-x: hidden;
}

* {
  font-family: $titleFont, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}

.landing-pages {
  font-family: $mainSansSerif;

  h1,h2,h3 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-weight: 900;
    color: $alwaysBlack;
  }
  h1 {
    font-size: 35px;
    line-height: 41px;
  }

  h2 {
    font-size: 30px;
    line-height: 36px;
  }

  h3 {
    font-size: 25px;
    line-height: 28px;
  }

  form {
    input,
    textarea {
      margin: 20px auto 0;
      padding: 14px 24px;
      width: 100%;
      background: $defaultWhite;
      height: 45px;
      border: none;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      color: $titleBlack;
      font-size: 14px;
      line-height: 16px;
      box-shadow: 0 15px 30px $slideBoxShadow;
      resize: none;

      &::placeholder {
        display: inline;
        vertical-align: top;
      }

      &:focus,
      :active {
        outline: none;
        padding: 12px 22px;
        border: 2px solid $borderBlue;
      }
    }
  }
}

.header-logo {
  width: 185px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.news-img {
  width: 450px;
  margin: 0 auto;
  max-width: 95%;

  img {
    width: 100%;
    object-fit: contain;
  }
}


.news-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;

  @include media('<1024px') {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.news-preview {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  overflow: hidden;
  background: $primaryColor;
  color: #fff;
  text-align: center;
  padding-bottom: 15px;
  transition: all .2s ease;

  @include media('<1024px') {
    font-size: 12px;
  }

  &__img {
    height: 230px;
    width: 100%;

    @include media('<1024px') {
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 18px;
    text-align: center;
    font-family: $titleFont;
    text-transform: uppercase;
    padding: 0 15px;
    padding-top: 15px;

    @include media('<1024px') {
      font-size: 16px;
    }
  }

  &:hover {
    color: #fff;
    opacity: .85;
  }
}

.news-section {
  padding-bottom: 0;
}
.news-content {
  background: $primaryColor;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid $primaryColor;
  margin: 20px 0;
  &__content {
    padding: 20px;
  }

  &__img {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    @include media('<1024px') {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 18px;
    color: #fff !important;
    margin-bottom: 10px !important;
  }

  &__text {
    opacity: .85;
    color: #fff;
  }
}


.text-box-content {
  color: #000 !important;
  margin: 20px auto;
  background: #fff;
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, .2);
  padding: 20px;
  border-radius: 25px;
  width: 770px;
  font-size: 12px;
  text-align: justify;

  strong {
    font-weight: 700;
  }
  p + p {
    margin-top: 10px;
  }
  h2 {
    font-size: 21px;
    margin-bottom: 20px;
  }

  .definitions {
    line-height: 1.2;
    padding-top: 20px;

    strong {
      font-size: 14px;
    }
  }

  .points {
    margin-top: 20px;

    > * + * {
      margin-top: 20px;
    }
  }

  .point-group-main {
    margin-bottom: 5px;

    > * + * {
      margin-top: 10px;
    }
  }

  .point-group-subpoints {
    padding-left: 10px;
    font-size: 10px;
    line-height: 1.2;

    > * + * {
      margin-top: 10px;
    }
  }

  .subpoints-lvl-2 {
    padding-left: 10px;

    > * + * {
      margin-top: 5px;
    }
  }
}