.faq-nav {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  li+li {
    margin-left: 15px;
  }
  button {
    border: 1px solid $primaryColor;
    color: #000;
    background: transparent;
    &.selected {
      color: #fff;
      background: $primaryColor;
    }
  }
}

.faq-item-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-top: 30px;
  &__item {
    width: calc(100% / 3);
    padding: 0 10px;
    padding-bottom: 20px;
  }
}
.faq-item {
  &__question {
    font-size: 16px;
    margin: 0;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__answer {
    font-size: 12px;
    margin: 0;
    line-height: 1.5;
    font-style: italic;
    opacity: .5;
  }
}