

.testimonials {
  align-content: center;
  margin: 40px 0 100px 0;

  .ant-card-head {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    color: $whiteMain;
  }

  .testimonial-card {
    margin: 15px 20px;
    &.ant-card {

      background: $grayBackground;
      color: $whiteMain;
      &.ant-card-bordered {
        border-color: transparent;
      }
    }
  }

  .testimonial-card.ant-card {
    background: #fff;
    box-shadow: 0 10px 20px -4px rgba(0, 0, 0, .2);
    color: #000 !important  ;

    .ant-card-head-title {
      color: #000;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 0;
    }
    a {
      color: $primaryColor;
    }
  }
}
